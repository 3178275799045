import React from "react";
import "./css/genre.css";
import science from "./images/science.svg";
import selfhelp from "./images/selfhelp.svg";
import business from "./images/business.svg";
import parenting from "./images/parenting.svg";
import health from "./images/health.svg";
import fiction from "./images/fiction.png";
import comic from "./images/comics.png";
import Nature from "./images/nature.png";
import Adventure from "./images/adventure.png";
import mystery from "./images/mystery.png";

function Container3() {
  const genres = [
    { title: "Self Help", image: selfhelp },
    { title: "Business", image: business },
    { title: "Science & Tech", image: science },
    { title: "Parenting", image: parenting },
    { title: "Health & Wellness", image: health },
    { title: "Fiction", image: fiction },
    { title: "Comic", image: comic },
    { title: "Nature", image: Nature },
    { title: "Adventure", image: Adventure },
    { title: "Mystery", image: mystery },
  ];

  return (
    <div
      style={{
        backgroundColor: "#FFF8F5",
        padding: "24px",
        paddingLeft: "85px",
        paddingRight: "85px",
      }}
      id="wrapperDiv"
    >
      <section
        id="genre"
        style={{
          background: "#FFF8F5",
          padding: "50px 0",
          margin: "0",
          textAlign: "center" ,
          flex: "1" ,
          maxWidth: "100%" ,
        }}
      >
        <div
          className="container-fluid text-center"
          style={{ padding: 0, margin: 0 }}
        >
          <h2 className="section-title">Popular Genres</h2>
          <p className="section-description">
            Discover summaries across popular genres—whether you're into
            self-improvement, business strategies, the latest tech trends, or
            wellness tips. There's something here for every curious mind!
          </p>
          <div className="genres-grid">
            {genres.map((genre, index) => (
              <div className="genre-card" key={index}>
                <img
                  src={genre.image}
                  alt={genre.title}
                  className="genre-image"
                />
                <div className="genre-title">{genre.title}</div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Container3;
