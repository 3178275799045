import React from "react";
import Accordion from "react-bootstrap/Accordion";

function Container7() {
  return (
    <div
      style={{
        backgroundColor: "#FFF8F5",
        padding: "24px",
        paddingLeft: "85px",
        paddingRight: "85px",
      }}
      id="wrapperDiv"
    >
      <section
        id="faq"
        className="faq-section py-5"
        style={{
          backgroundColor: "#FFF8F5",
          margin: 0,
          paddingLeft: 0,
          paddingRight: 0,
          maxWidth: "100%",
        }}
      >
        <div className="text-center mb-4">
          <h2 className="fw-bold">Frequently Asked Questions</h2>
          <p className="text-muted">
            Find answers to some of the most common questions about our services
            and features. If you have more questions, feel free to reach out to
            our support team!
          </p>
        </div>

        <Accordion className="custom-accordion">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="custom-header">
              How does the AI-powered recommendation system work?
            </Accordion.Header>
            <Accordion.Body>
              The AI-powered recommendation system works by analyzing user
              preferences and recommending books accordingly.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header className="custom-header">
              Can I listen to audiobooks offline?
            </Accordion.Header>
            <Accordion.Body>
              Yes, you can download audiobooks to listen offline using our app.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header className="custom-header">
              What genres are available?
            </Accordion.Header>
            <Accordion.Body>
              We offer a wide range of genres, including motivation, psychology,
              business, and health & wellness.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header className="custom-header">
              How do I join the community?
            </Accordion.Header>
            <Accordion.Body>
              You can join the community by signing up on our platform and
              participating in discussions.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4">
            <Accordion.Header className="custom-header">
              Is there a free trial available?
            </Accordion.Header>
            <Accordion.Body>
              Yes, we offer a 7-day free trial for new users to explore our
              platform.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="5">
            <Accordion.Header className="custom-header">
              How do I contact customer support?
            </Accordion.Header>
            <Accordion.Body>
              You can contact our customer support via the support section on
              our website or by email.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </section>
    </div>
  );
}

export default Container7;
