import React from "react";
import "./css/reviews.css"; // Link to CSS file for custom styles

function Container4() {
  const reviews = [
    {
      stars: 5,
      text: "This app has completely transformed the way I read! The summaries are concise, engaging, and insightful. It saves me hours while still delivering the essence of each book. A must-have for busy readers!",
      user: "Anant Yadav",
      image: "images/profile1.jpg",
    },
    {
      stars: 5,
      text: "I love how accurate and well-structured the summaries are. The AI captures the key points without missing important insights. Now I can explore more books in less time. Highly recommend it!",
      user: "Sarah Williams",
      image: "images/profile2.jpeg",
    },
    {
      stars: 5,
      text: "This app has been a game-changer! The AI-generated summaries are so on point that I often don't feel the need to read the full book. Great tool for both learning and leisure.",
      user: "Daniel Anderson",
      image: "images/profile3.jpg",
    },
    {
      stars: 5,
      text: "The multilingual support is brilliant! As someone who reads in multiple languages, I love the flexibility this app offers. The summaries are well-written, concise, and enjoyable. 5 stars from me!",
      user: "Carol Slinker",
      image: "images/profile4.jpg",
    },
    {
      stars: 5,
      text: "This app makes it so easy to stay up-to-date with books I’ve been wanting to read. The personalized recommendations are spot-on, and the summaries always feel fresh and engaging.",
      user: "Jillian Emmert",
      image: "images/profile5.jpg",
    },
    {
      stars: 5,
      text: "I’ve tried several book summary apps, but this one stands out. The quality of the summaries and ease of use are unmatched. Perfect for anyone looking to learn on the go!",
      user: "David Brown",
      image: "images/profile6.jpg",
    },
  ];

  return (
    <div
      style={{
        backgroundColor: "#FFF8F5",
        padding: "24px",
        paddingLeft: "85px",
        paddingRight: "85px",
      }}
      id="wrapperDiv"
    >
      <section
        id="testimonials"
        style={{
          background: "#FFF8F5",
          padding: "50px 0",
          margin: "0",
          textAlign: "center" ,
          flex: "1" ,
          maxWidth: "100%" ,
        }}
      >
        <div
          className="container text-center"
          style={{ padding: 0, margin: 0 }}
        >
          <h2 className="section-title">What Our Users Love</h2>
          <p className="section-description">
            Hear directly from our users about how this app has enhanced their
            reading experience. Discover why readers love the quick, insightful
            summaries and seamless functionality that help them stay informed
            and inspired.
          </p>
          <div className="reviews-grid">
            {reviews.map((review, index) => (
              <div className="review-card" key={index}>
                <div className="stars">{"★".repeat(review.stars)}</div>
                <p className="review-text">{review.text}</p>
                <div className="user-info">
                  <img
                    src={review.image}
                    alt={review.user}
                    className="user-image"
                  />
                  <span className="user-name">{review.user}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Container4;
