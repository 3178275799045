import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "./images/flickerapp.svg";
import "./css/extra.css";

function Navigatebar() {
  return (
    <Navbar
      expand="xl" // Ensure it collapses only for 'lg' and smaller devices
      style={{
        backgroundColor: "#FFF8F5",
        minWidth: "200px",
        paddingLeft: "85px",
        paddingRight: "85px",
      }}
      id="wrapperDiv"
    >
      <Container fluid style={{ padding: 0 }}>
        {/* Brand Section */}
        <div className="d-flex justify-content-start align-items-center">
          <img
            style={{
              height: "100%",
              maxHeight: "1.5em",
              width: "auto",
              marginRight: "8px",
            }}
            src={logo}
            alt=""
          />
          <Navbar.Brand href="#" style={{ color: "#BB1314" }}>
            Flicker App
          </Navbar.Brand>
        </div>

        {/* Collapsible Toggle */}
        <Navbar.Toggle aria-controls="navbarScroll" />

        {/* Navbar Links Section */}
        <Navbar.Collapse id="navbarScroll">
          <div className="d-flex justify-content-center w-100 flex-wrap">
            <Nav className="mx-auto">
              <Nav.Link href="#about" className="px-3">
                About Us
              </Nav.Link>
              <Nav.Link href="#genre" className="px-3">
                Popular Genre
              </Nav.Link>
              <Nav.Link href="#testimonials" className="px-3">
                Testimonials
              </Nav.Link>
              <Nav.Link href="#collections" className="px-3">
                Top Collections
              </Nav.Link>
              <Nav.Link href="#pricing" className="px-3">
                Pricing
              </Nav.Link>
              <Nav.Link href="#faq" className="px-3">
                FAQ
              </Nav.Link>
            </Nav>
          </div>

          {/* Buttons Section */}
          <div className="d-none d-lg-flex justify-content-center mt-2">
            <Button
              variant="outline-success"
              className="me-2"
              style={{
                minWidth: "100px",
                backgroundColor: "#BB1314",
                border: "none",
                color: "white",
              }}
            >
              Log In
            </Button>
            <Button
              variant="outline-success"
              style={{
                minWidth: "100px",
                borderColor: "#BB1314",
                color: "#BB1314",
                backgroundColor: "white",
              }}
            >
              Sign Up
            </Button>
          </div>

          {/* Centered buttons for small screens */}
          <div className="d-flex d-lg-none justify-content-center w-100 mt-2">
            <Button
              variant="outline-success"
              className="me-2"
              style={{
                minWidth: "100px",
                backgroundColor: "#BB1314",
                border: "none",
                color: "white",
              }}
            >
              Log In
            </Button>
            <Button
              variant="outline-success"
              style={{
                minWidth: "100px",
                borderColor: "#BB1314",
                color: "#BB1314",
                backgroundColor: "white",
              }}
            >
              Sign Up
            </Button>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigatebar;
