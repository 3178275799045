import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import "./css/Container1.css"; // Custom CSS
import widget from "./images/Widget.svg";
import overlay1 from "./images/overlay1.png";
import overlay2 from "./images/overlay2.png";

function Container1() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "stretch",
        backgroundColor: "#FFF8F5",
        padding: "24px",
        paddingLeft: "85px",
        paddingRight: "85px",
      }}
      id="wrapperDiv"
    >
      <section
        className="section1 text-center"
        style={{
          flex: "1",
          maxWidth: "100%",
          background: "linear-gradient(to right, #BB1314, #ED553B)",
          opacity: "0.8",
          borderRadius: "20px",
          padding: "50px 20px",
          margin: "0",
        }}
      >
        <Container fluid>
          <Row className="align-items-center">
            {/* Text and Buttons Column */}
            <Col xs={12} md={6} className="text-md-start mb-4 mb-md-0">
              <h1 className="display-4 fw-bold text-white">
                Save Time. Learn Fast.
                <br />
                Dive into Book Summaries!
              </h1>
              <p className="lead text-white">
                Book summaries that make learning quick and engaging. Discover
                new books, save time, and get personalized recommendations.
              </p>

              <div className="cta-buttons d-flex flex-column flex-md-row justify-content-center justify-content-md-start mt-4">
                <Button
                  variant="light"
                  className="me-md-3 mb-3 mb-md-0 px-4 py-2"
                  style={{ fontWeight: "bold" }}
                >
                  Start Now For Free
                </Button>
                <Button
                  variant="outline-light"
                  className="px-4 py-2"
                  style={{ fontWeight: "bold" }}
                >
                  AI Search
                </Button>
              </div>

              {/* Active Users Section */}
              <div className="d-flex align-items-center mt-4 justify-content-center justify-content-md-start">
                <div className="d-flex">
                  {["profile8.jpg", "profile5.jpg", "profile3.jpg"].map(
                    (src, index) => (
                      <Image
                        key={index}
                        src={`images/${src}`}
                        alt={`User ${index + 1}`}
                        className="user-avatar"
                      />
                    )
                  )}
                </div>
                <p
                  className="ms-3 mb-0 text-white fw-bold"
                  style={{ fontSize: "18px" }}
                >
                  1000+ Active Users Now
                </p>
              </div>
            </Col>

            {/* Image Column with Overlays */}
            <Col xs={12} md={6} className="text-center position-relative">
              <div className="book-cover-container">
                <Image
                  src="images/book-cover.jpg"
                  alt="Book Cover"
                  fluid
                  className="book-cover-image"
                />

                <Image
                  src={overlay1}
                  alt="User"
                  className="user-favorite-overlay"
                />

                <Image
                  src={overlay2}
                  alt="User"
                  className="user-favorite-overlay2"
                />

                {/* Bottom-Center Media Player Overlay */}
                <div className="media-player-overlay">
                  <div className="audio-player-wrapper">
                    <Image
                      src={widget}
                      alt="Play"
                      className="audio-player-image"
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default Container1;
