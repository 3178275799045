import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

function Container6() {
  const [isYearly, setIsYearly] = useState(false);

  const plans = [
    {
      title: "Free Plan",
      price: "$0",
      duration: isYearly ? "/year" : "/month",
      description: "Get started with basic features available for free.",
      features: [
        { text: "AI-Powered Summaries", available: true },
        { text: "AI Search", available: true },
        { text: "Access to 5 Summaries", available: true },
        { text: "No Offline Access", available: false },
        { text: "Custom Recommendations", available: false },
        { text: "Priority Support", available: false },
      ],
      backgroundColor: "#f7fff2",
      buttonColor: "#5edc11",
      borderColor: "#5edc11",
    },
    {
      title: "Basic Plan",
      price: isYearly ? "$250" : "$25",
      duration: isYearly ? "/year" : "/month",
      description: "Unlock essential features for regular readers.",
      features: [
        { text: "AI-Powered Summaries", available: true },
        { text: "AI Search", available: true },
        { text: "Unlimited Summaries", available: true },
        { text: "Offline Access", available: true },
        { text: "Custom Recommendations", available: false },
        { text: "Priority Support", available: false },
      ],
      backgroundColor: "#f7faff",
      buttonColor: "linear-gradient(to right, #0063ff, #64cfff)",
      borderColor: "#0063ff",
    },
    {
      title: "Standard Plan",
      price: isYearly ? "$350" : "$35",
      duration: isYearly ? "/year" : "/month",
      description: "Enjoy premium features with additional perks.",
      features: [
        { text: "AI-Powered Summaries", available: true },
        { text: "AI Search", available: true },
        { text: "Unlimited Summaries", available: true },
        { text: "Offline Access", available: true },
        { text: "Custom Recommendations", available: true },
        { text: "Priority Support", available: true },
      ],
      backgroundColor: "#ffe5ce",
      buttonColor: "#ff7222",
      borderColor: "#ff7222",
    },
  ];

  return (
    <div
      style={{
        backgroundColor: "#FFF8F5",
        padding: "24px",
        paddingLeft: "85px",
        paddingRight: "85px",
      }}
      id="wrapperDiv"
    >
      <section
        id="pricing"
        style={{
          backgroundColor: "#FFF8F5",
          padding: "50px 0",
          margin: "0",
          flex: "1",
          maxWidth: "100%",
        }}
      >
        <Container fluid style={{ padding: 0 }}>
          <h2 className="text-center fw-bold">Choose Your Plan</h2>
          <p className="text-center text-muted">
            Explore our flexible plans designed to fit your reading habits and
            preferences. Whether you're looking for a free option or premium
            features, we have the right plan to meet your needs.
          </p>
          <div className="text-center mb-4">
            <Button
              variant={isYearly ? "outline-primary" : "primary"}
              className="rounded-pill me-2"
              onClick={() => setIsYearly(false)}
              style={{
                background: isYearly
                  ? ""
                  : "linear-gradient(to right, #0063ff, #64cfff)",
              }}
            >
              Monthly
            </Button>
            <Button
              variant={isYearly ? "primary" : "outline-primary"}
              className="rounded-pill"
              onClick={() => setIsYearly(true)}
              style={{
                background: isYearly
                  ? "linear-gradient(to right, #0063ff, #64cfff)"
                  : "",
              }}
            >
              Yearly
            </Button>
          </div>

          <Row className="g-4 d-flex justify-content-between">
            {plans.map((plan, index) => (
              <Col
                xs={12}
                sm={6} 
                md={6} 
                lg={4} 
                xl={3} // Four cards per row on extra-large screens
                key={index}
                className="d-flex"
              >
                <Card
                  className="text-center shadow-sm h-100 w-100" // Ensure card takes full width and height
                  style={{
                    backgroundColor: plan.backgroundColor,
                    borderColor: plan.borderColor,
                  }}
                >
                  <Card.Body>
                    <Card.Title className="fw-bold">{plan.title}</Card.Title>
                    <Card.Text style={{ fontSize: "2rem" }}>
                      {plan.price}
                      <sup
                        style={{
                          color: "lightgrey",
                          fontSize: "0.6em",
                          marginLeft: "5px",
                        }}
                      >
                        {plan.duration}
                      </sup>
                    </Card.Text>
                    <Card.Text>{plan.description}</Card.Text>
                    <ul className="list-unstyled mb-4 text-start">
                      {plan.features.map((feature, idx) => (
                        <li
                          key={idx}
                          className="d-flex align-items-start"
                          style={{
                            padding: "4px 0",
                            wordBreak: "break-word",
                            gap: "8px",
                          }}
                        >
                          <div
                            style={{
                              flexShrink: 0,
                              minWidth: "24px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {feature.available ? (
                              <FaCheckCircle
                                className="text-success"
                                size={20}
                              />
                            ) : (
                              <FaTimesCircle
                                className="text-danger"
                                size={20}
                              />
                            )}
                          </div>
                          <span style={{ flex: 1 }}>{feature.text}</span>
                        </li>
                      ))}
                    </ul>
                    <Button
                      className="w-100"
                      style={{
                        background: plan.buttonColor,
                        color: "white",
                        border: "none",
                      }}
                    >
                      Choose Plan
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default Container6;
